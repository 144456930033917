/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { SwitchToggleContainer, InputCheckbox, Slider, CardBody, CardContainer, ContentDetail, AddOnsDetails, AutoRenewContainer } from './styles'
import { useAppSelector } from '../../../../app/hooks'
import LoadingOverlay from '../../../../components/Loading-overlay'
import NoticeModal from '../../../../components/notice-modal'
import { useGetCompanyGroupsQuery, useGetSubscriptionDetailsQuery, useGetUserDetailsByIdQuery, useUpdateAutoRenewalMutation } from '../../../../app/services'
import { isMobile } from 'react-device-detect'
import { Users as UsersFromStore } from '../../../../app/slices/Users'
import './style.css'
import { getCSURFToken } from '../../../../utils/CSRFToken'
import { PLANS } from '../../../../configs'
import { AddOnsButton } from '../add-ons/styles'
import { useHistory } from 'react-router-dom'
import ErrorModal from '../../../../components/error-modal'
interface SubscriptionState {
	addOns: Array<{
		quantity: number;
	}>;
	end: string;
	planId: string;
	price: number;
	status: string;
	_id: string | null;
	noSubscriptions: boolean;
	planLabel: string;
}

function SubscriptionDetail(): ReactElement {
	const [mySubscription, setMySubcription] = useState<SubscriptionState>({
		addOns: [],
		end: '',
		planId: '',
		price: 0,
		status: '',
		_id: null,
		noSubscriptions: false,
		planLabel: ''
	})

	const [state, setState] = useState({
		isLoading: true,
		isToggleRenewal: false,
		renewalModalMsg: '',
		renewalStatus: false,
		error: {
			isShow: false,
			message: ''
		}
	})
	const [subscriptionError, setSubscriptionError] = useState({
		isError: false,
		message: ''
	})
	const { userData } = useAppSelector(UsersFromStore)
	const { data, isLoading: fetchingSubEntryLoading, isError: fetchingSubEntryisError } = useGetSubscriptionDetailsQuery(userData?.subscription?._id, { skip: !userData?.subscription?._id })
	const [executeUpdateAutoRenewalMutation, { data: updateAutoRenewalData, isLoading: updateAutoRenewalIsLoading, isError: updateAutoRenewalIsError }] = useUpdateAutoRenewalMutation()
	const { data: fetchData } = useGetCompanyGroupsQuery(userData?._id, { skip: !userData?._id })
	const [renewalPlanTotalPrice, setRenewalPlanTotalPrice] = useState(0.00)
	const [totalActiveAccounts, setTotalActiveAccounts] = useState(0)
	const history = useHistory()
	const isSubsExpired = new Date(userData?.subscription?.end) < new Date()
	const { data: user, refetch: userRefetch } = useGetUserDetailsByIdQuery(userData?._id, { skip: !userData?._id })
	const isUserCommercialWithAddons = mySubscription?.addOns[0]?.quantity
	const isCurrentUserCommercial = mySubscription?.planId.includes('commercial')
	const isCurrentUserPersonal = mySubscription?.planId.includes('personal')
	const isCurrentUserFree = userData?.orbit_roles[0].includes('customer-free')
	const isCurrentUserHaveActiveSubscription = userData?.subscription
	const changePLanId = userData?.change_plan
	const [planLabel, setPlanLabel] = useState<string>('')
	const [planPrice, setplanPrice] = useState<string>('')
	const isCurrentUserTrialSubscription = userData?.subscription?.trial

	const handleFilterChangePlanId = () => {
		const filteredPlanId = PLANS.filter(plan => plan.planId === changePLanId)
		setPlanLabel(filteredPlanId[0]?.label)
		setplanPrice(filteredPlanId[0]?.price)
	}

	useEffect(() => {
		if (data) {
			if (data.success) {
				const planLabel = PLANS.find(plan => plan.planId === data?.data?.planId)
				if (planLabel) {
					setMySubcription({ ...data.data, noSubcriptions: false, planLabel: planLabel.label })
				}
				const planPrice = data.data.price
				const addOnsQty = data.data?.addOns[0] ? data.data?.addOns[0]?.quantity : 0
				const addOnsTotalPrice = addOnsQty * 129
				setRenewalPlanTotalPrice(data.data?.addOns[0]?.quantity ? addOnsTotalPrice + planPrice : planPrice)
				if (userData) {
					if (userData.change_plan) {
						const changePlan = PLANS.find(plan => plan.planId === userData.change_plan)
						if (changePlan) {
							if (changePlan.planId === 'commercial-1-year') {
								setRenewalPlanTotalPrice(data.data?.addOns[0] ? addOnsTotalPrice + changePlan.numericPrice : changePlan.numericPrice)
							} else {
								setRenewalPlanTotalPrice(changePlan.numericPrice)
							}
						}
					}
				}

			} else {
				setMySubcription({
					addOns: [],
					end: '',
					planId: '',
					price: 0,
					status: '',
					_id: null,
					noSubscriptions: true,
					planLabel: ''
				})
			}
			setState(state => ({ ...state, isLoading: false }))
		}

		if (fetchingSubEntryisError) {
			console.log('Something went wrong. Please try again.')
			setState(state => ({ ...state, isLoading: false }))
		}

		if (userData) {
			if (userData?.auto_renewal_status) {
				setState(state => ({ ...state, renewalStatus: userData?.auto_renewal_status === 'ON' ? false : true }))
			}
			setState(state => ({ ...state, isLoading: false }))
		}
	}, [data, fetchingSubEntryisError, userData])

	useEffect(() => {
		if (fetchData) {
			if (fetchData.success) {
				if (fetchData.data.length) {
					let totalActiveAccounts = 0
					fetchData.data.forEach((data: any) => {
						if (data.profile && data.profile.account_status === 'active' && data.subscription.manager.invite_status !== 'Pending') {
							totalActiveAccounts++
						}
					})
					setTotalActiveAccounts(totalActiveAccounts)
				}
			}
		}
		handleFilterChangePlanId()
	}, [fetchData])

	const openErrorModal = (message: string) => {
		setState((state: any) => ({ ...state, error: { isShow: true, message: message } }))
	}

	const handleOpenNoticeModal = () => {
		setState(state => ({ ...state, isToggleRenewal: true, renewalModalMsg: `Are you sure you want to turn ${state.renewalStatus ? 'on' : 'off'} auto-renewal? Your subscription will expire on ${moment(mySubscription.end).format('MMM DD, YYYY')}.` }))
	}

	const handleChangeRenewal = async (isConfirm: boolean) => {
		if (isConfirm) {
			setState(state => ({ ...state, isToggleRenewal: false, isLoading: true }))
			const autoRenewalStatus = state.renewalStatus === true ? 'ON' : 'OFF'
			const payload = {
				userId: userData?._id,
				status: autoRenewalStatus
			}

			const csrf_token = await getCSURFToken()
			await executeUpdateAutoRenewalMutation({ params: payload, token: csrf_token })
			userRefetch()
		} else {
			setState(state => ({ ...state, isToggleRenewal: false }))
		}
	}

	useEffect(() => {
		if (updateAutoRenewalData) {
			if (updateAutoRenewalData.success) {
				setState(prevState => ({
					...prevState,
					isLoading: false,
					renewalStatus: !prevState.renewalStatus
				}))
			} else {
				openErrorModal(updateAutoRenewalData.message)
			}
		}

		if (updateAutoRenewalIsError) {
			setState(state => ({ ...state, isLoading: false }))
			openErrorModal('Something went wrong. Please try again.')
		}
	}, [updateAutoRenewalData, updateAutoRenewalIsError])

	const addOnsQuantity = () => {
		return mySubscription?.addOns[0] ? mySubscription?.addOns[0]?.quantity : 0
	}

	function convertedAddoOns(quantity: number, totalRecords: number) {
		if (isNaN(totalRecords - quantity) || quantity - totalRecords === -1) {
			return 0
		} else {
			return quantity - totalRecords
		}
	}

	const ContentDetails = ({ children, title }: { children: ReactNode; title: string }): ReactElement => {
		return <ContentDetail><span className='title'>{title}</span> {children}</ContentDetail>
	}

	const handleSubscriptionChecker = () => {
		if (isSubsExpired) {
			setSubscriptionError({
				isError: true,
				message: 'You are not authorized to buy additional seats.'
			})
			return
		} else {
			history.push(`/buy-addons/${userData?._id}`)
		}
	}

	return (
		<>
			{state.isLoading && <LoadingOverlay />}
			{fetchingSubEntryLoading && <LoadingOverlay />}
			{updateAutoRenewalIsLoading && <LoadingOverlay />}
			<div className='subcription-details-container'>
				<CardContainer isMobile={isMobile}>
					<CardBody isMobile={isMobile}>
						<p className='head-body-subsription'>Your Subscription Details</p>
						<div>
							<ContentDetails title='Plan:'>{mySubscription.planLabel ? mySubscription.planLabel : 'You are currently not subscribed to any kind of subscription.'} {isCurrentUserTrialSubscription && <span style={{fontStyle: 'italic'}}>( Trial )</span>}</ContentDetails>
							<ContentDetails title='Expiration Date:'>{mySubscription.end ? moment(userData?.subscription?.end).format('MMM DD, YYYY') : 'N/A'}</ContentDetails>
							{
								userData?.subscription != null && <AutoRenewContainer>
									<span>
										<span className='title'>Auto Renew: &nbsp;</span>
										<span className='onLabel title'>{userData?.subscription == null || isSubsExpired || isCurrentUserFree || user?.data?.auto_renewal_status == 'OFF' ? 'OFF' : 'ON'} &nbsp; </span>
										<SwitchToggleContainer disabled={(userData?.subscription == null || isSubsExpired)}>
											{/* checked.false => colored, checked.true => grayed out */}
											<InputCheckbox type="checkbox" checked={!userData?.subscription == null || isSubsExpired || isCurrentUserFree || user?.data?.auto_renewal_status == 'OFF'} onChange={handleOpenNoticeModal} />
											<Slider />
										</SwitchToggleContainer>
									</span>
									{
										isCurrentUserTrialSubscription ? '' : isCurrentUserHaveActiveSubscription || isCurrentUserTrialSubscription || isCurrentUserTrialSubscription !== undefined
											? <p style={{ fontWeight: 'bold', fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => history.push('/change-plan')}>CHANGE PLAN</p>
											: null
									}
								</AutoRenewContainer>
							}
							{
								(userData?.subscription == null || isSubsExpired)
									? null
									: <p style={{ textAlign: 'left', fontSize: 14, color: '#000', marginLeft: isMobile ? 0 : 24 }}>
										{changePLanId === undefined || changePLanId === null 
											? null
											: <span>Your subscription plan will be changed to <b>{planLabel}</b> for <b>{planPrice}</b> on the next renewal.</span>
										}
										{isMobile ? null : <>&nbsp;</>}
										Your default payment method will be charged automatically on <b>{moment(mySubscription.end).format('MMM DD, YYYY')}</b>
									</p>
							}
							{
								(isUserCommercialWithAddons || isCurrentUserCommercial || !isCurrentUserPersonal)
									? <ContentDetails title='Add-Ons:'>{userData?.subscription == null || isCurrentUserPersonal || isCurrentUserFree ? 'NO' : 'YES'}</ContentDetails>
									: null
							}
							{
								(isUserCommercialWithAddons || isCurrentUserCommercial) && !isCurrentUserFree
									? <AddOnsDetails isMobile={isMobile}><p>Additional Seats: <span>{addOnsQuantity()}</span>(${addOnsQuantity() * 129})</p>
										<span>{convertedAddoOns(mySubscription?.addOns[0]?.quantity, totalActiveAccounts)} Available &nbsp;
											{isCurrentUserCommercial ? <a style={{ cursor: 'pointer' }} onClick={() => handleSubscriptionChecker()}>Add More</a> : <a style={{ cursor: 'pointer' }} onClick={() => handleSubscriptionChecker()}>EDIT</a>}
										</span>
									</AddOnsDetails>
									: null
							}

							<ContentDetails title='Total Price:'>${renewalPlanTotalPrice}{!renewalPlanTotalPrice.toString().includes('.') && <>.00</>}</ContentDetails>

							{
								(isSubsExpired || userData?.subscription == null) && <div style={{ display: 'flex', justifyContent: 'center', height: 40 }}><AddOnsButton onClick={() => history.push('/subscriptions')}>BUY SUBSCRIPTION</AddOnsButton></div>
							}
						</div>
					</CardBody>
				</CardContainer>
			</div>
			{!state.isToggleRenewal ? '' : <NoticeModal messageBody={state.renewalModalMsg} title={'RENEWAL'} isOpen={state.isToggleRenewal} onClose={handleChangeRenewal} isBlocked={false} />}
			{subscriptionError.isError && <ErrorModal messageBody={subscriptionError.message} isOpen={subscriptionError.isError} onClose={() => setSubscriptionError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' />}

		</>
	)
}

export default SubscriptionDetail

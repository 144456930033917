/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { IsUserAuthenticated } from './AuthenticationUtil'

export const Subscription_Type = { // Enums
	Commercial: 'commercial',
	Personal: 'personal',
}

export const reverseObfuscateConstant = (obfuscatedString: string): string => {
	let originalString = ''
	for (let i = 0; i < obfuscatedString.length; i++) {
		originalString += String.fromCharCode(obfuscatedString.charCodeAt(i) - 1)
	}
	return originalString
}

export const isUserAuthAndExpired = (userData: any): boolean => {

	if(userData){
		const subscription = userData?.subscription
		const subscriptiopnEnd = subscription?.end
		const orbitRole = userData.orbit_roles

		if(orbitRole.includes('project:customer-commercial'))
			return true

		if(orbitRole.includes('project:customer-free') || (new Date(subscriptiopnEnd) <= new Date()) || !subscription) 
			return false
		
		return true
	}

	return false

}
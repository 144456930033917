/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FieldValues, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import ProfileCard from '../components/component-cards/ProfileCard'
import PaymentCard from '../components/component-cards/PaymentCard'
import SubscriptionCard from '../components/component-cards/SubscriptionCard'
import { useGetCustomerPaymentMethodQuery, useGetTradeDirectoryLookupCountriesQuery, useGetUserLoginMutation, useSignUpExistingUserUpdgradeCommercialMutation, useSignUpNewUserMutation } from '../../../../../app/services'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import ModalContainer from '../../../../../components/modal-container/ModalContainer'
import AddNewCard from '../../gift-subscription/gift-sub/components/AddNewCard'
import ErrorModal from '../../../../../components/error-modal'
import NoticeModal from '../../../../../components/notice-modal'
import { isUserAuthAndExpired } from '../../../../../utils/Constant'
import { SaveAuthenticationSession } from '../../../../../utils'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { setCookie } from '../../../../../utils/CookieUtils'
import { deviceType } from '../../../../../helper/constants'
import { UpdateUserAuthDetails } from '../../../../../app/slices/Authentication'

import { GiftSubMainContainer, GiftSubTitle } from '../../gift-subscription/styles'

const plans = [
	{
		head: 'Personal Quarterly',
		price: 37,
		subText: 'Pay $37 every 3 months, save $32 a year',
		planId: 'personal-3-month'
	},
	{
		head: 'Personal Annual',
		price: 129,
		subText: 'Pay $129 a year, save $51',
		planId: 'personal-1-year'
	},
	{
		head: 'Personal Monthly',
		price: 15,
		subText: 'Pay $15 monthly',
		planId: 'personal-monthly'
	}
]

function PersonalCheckout(): ReactElement {
	const recaptchaRef = useRef(null)
	const { type } = useParams<{ type: string }>()
	const { userData } = useAppSelector(UsersFromStore)
	const history = useHistory()
	const dispatch = useDispatch()

	const [countries, setCountries] = useState<string[]>([])
	const [showAddModal, setShowAddModal] = useState(false)
	const [newCardDetails, setNewCardDetails] = useState<{ isNewCard: boolean, data: any }>({
		isNewCard: false,
		data: null
	})
	const [checkoutError, setCheckoutError] = useState({
		isError: false,
		message: ''
	})
	
	const { handleSubmit, getValues, setValue, setError, control, clearErrors } = useForm()
	const { control: cardControl, handleSubmit: cardHandleSubmit, reset: cardReset } = useForm()

	const { data: countryData } = useGetTradeDirectoryLookupCountriesQuery('')
	const { data: paymentData, isLoading: paymentListLoading } = useGetCustomerPaymentMethodQuery(userData?._id, { skip: !userData?._id })
	const [userLoginMutation, { isLoading: userLoginLoading }] = useGetUserLoginMutation()
	const [signUpNewUserMutation, { isLoading: signUpLoading }] = useSignUpNewUserMutation()
	const [signUpExistingMutation, { isLoading: signUpExistingLoading }] = useSignUpExistingUserUpdgradeCommercialMutation()

	const isTrial = !userData?._id && type === 'personal-1-year'
	const planTypeData = plans.find(plan => plan.planId === type)
	const isLogged = userData ? true : false
	
	useEffect(() => {
		if (countryData?.success) {
			const countryList = countryData.data.map((country: { _id: string; code: string; name: string; }) => country.name)
			setCountries(countryList)
		}
	}, [countryData])

	useEffect(() => {
		if(paymentData && paymentData.success){
			const paymentToken = paymentData?.data[0]?.token
			setValue('paymentMethodToken', paymentToken)
		}
	}, [paymentData])

	const loginUser = async (email: string, password: string) => {
		try{
			const csrf_token = await getCSURFToken()

			const loginPayload = {
				username: email,
				password: password,
				device: deviceType(),
			}
			const loginResultData = await userLoginMutation({params: loginPayload, token: csrf_token}).unwrap()

			if(!loginResultData.success){
				handleRecaptchaReset()
				return setCheckoutError({
					isError: true,
					message: loginResultData.message || loginResultData.data.message || JSON.stringify(loginResultData)
				})
			}
			
			SaveAuthenticationSession(
				'user',
				loginResultData.data.accessToken,
				loginResultData.data.accessTokenExpiresAt,
				loginResultData.data.refreshToken,
				loginResultData.data.refreshTokenExpiresAt,
				loginResultData.data.client._id,
				loginResultData.data.user._id
			)
			dispatch(UpdateUserAuthDetails({
				token: loginResultData.data.accessToken,
				tokenExpiry: loginResultData.data.accessTokenExpiresAt,
				refreshToken: loginResultData.data.refreshToken,
				refreshTokenExpiry: loginResultData.data.refreshTokenExpiresAt,
				clientId: loginResultData.data.client._id,
				country: loginResultData.data.country,
				userId: loginResultData.data.user._id
			}))

			setCookie('username', getValues('email'), true)

			history.push('/sign-up/success?type=personal')
			return window.location.reload()

		} catch(error: any) {
			handleRecaptchaReset()
			return setCheckoutError({
				isError: true,
				message: error.message ||  error.data.message || JSON.stringify(error)
			})
		}
	}

	const onSubmitHandler = async (formData: FieldValues) => {
		triggerTagManager()
		
		if (!formData.recaptcha)
			return setError('isAggrement', {
				type: ' custom',
				message: 'Recaptcha token is required.'
			})

		const countrySelected = countryData.data.find((country: any) => country.name === formData.country)
		const promoCode = formData.promoCode || ''
		
		const commonPayload: any = {
			title: '',
			email: formData.email,
			password: formData.password,
			firstName: formData.firstName,
			lastName: formData.lastName,
			receive_newsletter: formData.isNewsLetter ? 1 : 0,
			receive_marketingletter: formData.isMarketting ? 1 : 0,
			promoCode: promoCode,
			companyName: formData.companyName,
			companyTypes: formData.companyTypes,
			phone: formData.phone,
			fax: '',
			website: '',
			country: countrySelected,
			planId: type,
			recaptcha_token: formData.recaptcha,
		}

		let payload: any = {
			...commonPayload,
			card: {
				name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
				number: formData.cardNumber,
				expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
				cvv: formData.cvv,
				postalCode: formData.zipCode,
				paymentMethodToken: ''
			}
		}

		if(isLogged && newCardDetails.isNewCard)
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: newCardDetails.data.cardHolderFirstName + ' ' + newCardDetails.data.cardHolderLastName,
					number: newCardDetails.data.cardNumber,
					expirationDate: newCardDetails.data.expiryMonth + '/' + newCardDetails.data.expiryYear,
					cvv: newCardDetails.data.cvv,
					postalCode: newCardDetails.data.zipCode,
					paymentMethodToken: ''
				},
				
			}

		if(isLogged && !newCardDetails.isNewCard)
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: '',
					number: '',
					expirationDate: '',
					cvv: '',
					postalCode: '',
					paymentMethodToken: formData.paymentMethodToken
				}
			}

		if(userData && (!paymentData || (paymentData && !paymentData.success) || (paymentData && paymentData.success && !paymentData.data.length))){
			payload = {
				...commonPayload,
				customerId: userData?._id,
				card: {
					name: formData.cardHolderFirstName + ' ' + formData.cardHolderLastName,
					number: formData.cardNumber,
					expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
					cvv: formData.cvv,
					postalCode: formData.zipCode,
					paymentMethodToken: ''
				}
			}
		}

		try {
			const csrf_token = await getCSURFToken()

			if(isLogged){
				const signUpExistingUser = await signUpExistingMutation({ params: payload, token: csrf_token }).unwrap()

				if(!signUpExistingUser.success){
					handleRecaptchaReset()
					return setCheckoutError({
						isError: true,
						message: signUpExistingUser.message || signUpExistingUser.data.message || JSON.stringify(signUpExistingUser)
					})
				}
					

				history.push('/sign-up/success?type=personal')
				return window.location.reload()
			}

			const signUpNewUser = await signUpNewUserMutation({ params: payload, token: csrf_token }).unwrap()

			if(!signUpNewUser.success){
				handleRecaptchaReset()
				return setCheckoutError({
					isError: true,
					message: signUpNewUser.message || signUpNewUser.data.message || JSON.stringify(signUpNewUser)
				})
			}
			
			await loginUser(payload.email, payload.password)
		} catch (error: any) {
			handleRecaptchaReset()
			return setCheckoutError({
				isError: true,
				message: error?.message || error?.data?.message || JSON.stringify(error)
			})
		}
	}

	const cardFormSubmit = (cardDetails: FieldValues) => {
		if(cardDetails){
			setNewCardDetails({
				data: cardDetails,
				isNewCard: true,
			})
			setShowAddModal(false)
			setValue('paymentMethodToken', '')
			cardReset()
		}
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
		}
	}

	const triggerTagManager = () => {
		let event = {
			event: 'begin_purchase',
			ecommerce: {
				currency: 'USD',
				value: 37,
				items: [
					{
						item_name: 'Quaterly personal subscription',
						index: 0,            
						item_category: 'Personal',  
						item_category2: 'Quaterly', 
						price: 37 
					}
				]
			}
		}

		if(type === 'personal-1-year') {
			event = {
				event: 'begin_purchase',
				ecommerce: {
					currency: 'USD',
					value: 129,
					items: [
						{
							item_name: 'Annually personal subscription',
							index: 1,            
							item_category: 'Personal',  
							item_category2: 'Annually', 
							price: 129
						}
					]
				}
			}
		}

		if(type === 'personal-monthly') {
			event = {
				event: 'begin_purchase',
				ecommerce: {
					currency: 'USD',
					value: 15,
					items: [
						{
							item_name: 'Monthly personal subscription',
							index: 1,         
							item_category: 'Personal',  
							item_category2: 'Monthly', 
							price: 15
						}
					]
				}
			}
		}

		TagManager.dataLayer({
			dataLayer: {
				ecommerce: null
			}
		})

		TagManager.dataLayer({
			dataLayer: event
		})
	}

	return <>
		{paymentListLoading && <LoadingOverlay />}
		{userLoginLoading && <LoadingOverlay />}
		{signUpLoading && <LoadingOverlay />}
		{signUpExistingLoading && <LoadingOverlay />}
		<div className="single-entry">
			<div className="container" style={{ padding: isMobile ? 0 : 15 }}>
				<GiftSubMainContainer isMobile={isMobile}>
					<GiftSubTitle isMobile={isMobile}>
						<p>Checkout - ${planTypeData?.price}</p>
					</GiftSubTitle>
					<form className='gift-grid-container' onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='gift-grid'>
							<ProfileCard control={control} isLogged={userData?._id ? true : false} countries={countries} getValues={getValues} headerText='1. Personal Information' userData={userData} setValue={setValue} />
							<PaymentCard control={control} isLogged={userData?._id ? true : false} headerText='2. Payment Method' paymentData={paymentData} onAddNewCard={() => setShowAddModal(true)} newCardDetails={newCardDetails} />
						</div>
						<div className="gift-grid">
							<SubscriptionCard clearErrors={clearErrors} total={isTrial ? 0 : planTypeData?.price || 0} control={control} recaptchaRef={recaptchaRef} setValue={setValue} getValues={getValues} headerText='3. Subscription' setError={setError} planType={type} isLogged={userData?._id ? true : false}>
								<div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>{planTypeData?.head}</span>
									<span style={{ fontWeight: 'normal' }}>${planTypeData?.price}</span>
								</div>
								<div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>{planTypeData?.subText}</span>
								</div>
								{ isTrial && <div className='text-field' style={{ marginBottom: '1rem' }}>
									<span style={{ fontWeight: 'normal' }}>1 Week Free Trial</span>
									<span style={{ fontWeight: 'normal', color: '#BCA76F' }}>-$129</span>
								</div>}
							</SubscriptionCard>
						</div>
					</form>
				</GiftSubMainContainer>
			</div>
			{checkoutError.isError && <ErrorModal messageBody={checkoutError.message} isOpen={checkoutError.isError} onClose={() => setCheckoutError({ isError: false, message: '' })} bgColor='#73333F' closeTextColor='white' />}
			{isLogged &&
				<NoticeModal
					messageBody={`You currently have an active subscription that ends on ${moment(new Date(userData?.subscription?.end)).format('MMMM DD, YYYY')}. You can manage renewal of your subscription in Manage subscription.`}
					title={'NOTICE MESSAGE'}
					isOpen={isUserAuthAndExpired(userData)} onClose={() => history.push('/my-subscription')} isBlocked={true} />
			}
			<ModalContainer isOpen={showAddModal} onClose={() => setShowAddModal(false)}>
				<AddNewCard control={cardControl} handleSubmit={cardHandleSubmit} onSubmit={cardFormSubmit} onClose={() => setShowAddModal(false)} />
			</ModalContainer>
		</div></>
}

export default PersonalCheckout

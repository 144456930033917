/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react'
import { ActionsContainer, FieldErrorMessage, FormGroupNewUser, JoinGroupModalContainer, JoinGroupNewUseForm, ProceedButton, TitlePageNewUser, ValidatedField, ValidatedFieldSelect } from '../styles'
import { RP_ENV } from '../../../../../configs'
import { useGetJoinGroupInvitationNewUserQuery, useGetTradeDirectoryLookupCountriesQuery, useGetUserLoginMutation } from '../../../../../app/services'
import Icons from '../../redemption-codes/redeem/icons'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { UpdateUserAuthDetails, Authentication as AuthFromStore } from '../../../../../app/slices/Authentication'
import { setCookie } from '../../../../../utils/CookieUtils'
import { useDispatch } from 'react-redux'
import { SaveAuthenticationSession } from '../../../../../utils'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import { deviceType } from '../../../../../helper/constants'
import { useAppSelector } from '../../../../../app/hooks'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { dataCySelector } from '../../../../../app/services/constant'
import { reverseObfuscateConstant } from '../../../../../utils/Constant'
import { validateEmail } from '../../redemption-codes/constants'
import ErrorModal from '../../../../../components/error-modal'
import ConfirmJoinModal from './ConfirmJoinModal'

const groupInitial = {
	token: '',
	title: 'Mr.',
	isNew: false,
	formErrors: {},
	firstName: '',
	lastName: '',
	email: '',
	passwordConfirm: '',
	password: '',
	country: 'all',
	invitor: '',
	error: {
		isShow: false,
		message: ''
	}
}

const getErrors = (formErrors: any) => {
	let error = ''
	for (const i in formErrors) {
		error += formErrors[i]
	}
	return error
}

const JoinGroupNewUser = (): ReactElement => {
	const { pathname } = useLocation()
	const dispatch = useDispatch()
	const history = useHistory()
	const lookupCountries = useGetTradeDirectoryLookupCountriesQuery('')
	const { User } = useAppSelector(AuthFromStore)
	const [groupState, setGroupState] = useState<any>(groupInitial)
	const [isLoading, setIsLoading] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [errorState, setErrorState] = useState<any>()
	const [isConfirmModal, setIsConfirmModal] = useState<any>()

	useEffect(() => {
		fetchUserInvitation()
		return () => {
			setIsLoading(false)
			setGroupState(groupInitial)
		}
		//eslint-disable-next-line
	}, [])

	const getTokens = () => {
		const token = pathname?.split('/')
		if (token[3]) {
			return token[3]
		}
		return 'null'
	}
	const token = getTokens()
	const [isRunQuery, setIsRunQuery] = useState(true)

	const { data: fetchData, isError: getJoinGroupIsError } = useGetJoinGroupInvitationNewUserQuery(getTokens(), { skip: isRunQuery })

	const fetchUserInvitation = async () => {
		setIsLoading(true)
		try {
			setIsRunQuery(false)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (fetchData) {
			if (fetchData.success) {
				setGroupState((prevState: any) => ({
					...prevState,
					token: token,
					firstName: fetchData?.data?.user?.profile?.name?.first_name || '',
					lastName: fetchData?.data?.user?.profile?.name?.last_name || '',
					title: fetchData?.data?.user?.profile?.name?.title || '',
					email: fetchData?.data?.user?.username || '',
					invitor: fetchData?.data?.manager?.profile?.name?.first_name + ' ' + fetchData?.data?.manager?.profile?.name?.last_name || '',
				}))
			}
			setIsLoading(false)
		}

		if (getJoinGroupIsError) {
			console.log('Something went wrong. Please try again.')
		}

	}, [fetchData])


	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setGroupState((state: any) => ({ ...state, [event.target.id]: event.target.value }))
	}

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const val = lookupCountries.data.data.filter((ele: any) => ele._id === event.target.value)
		setGroupState((state: any) => ({ ...state, [event.target.id]: event.target.value, objectCountry: val[0] }))
	}

	const handleSelectBlur = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setGroupState((state: any) => ({ ...state, formErrors: { ...state.formErrors, [event.target.id]: (state[event.target.id] || state[event.target.id] === 'all') ? '' : 'Country is required' } }))
	}

	const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setGroupState((state: any) => ({ ...state, [event.target.id]: event.target.checked }))
	}

	const finalErrorValidation = (errorMessages: any, errors: any) => {
		errors = { ...errors, email: errorMessages.email ? errorMessages.email : '' }
		errors = { ...errors, firstName: errorMessages.firstName ? errorMessages.firstName : '' }
		errors = { ...errors, lastName: errorMessages.lastName ? errorMessages.lastName : '' }
		errors = { ...errors, country: errorMessages.country ? errorMessages.country : '' }
		errors = { ...errors, password: errorMessages.password ? errorMessages.password : '' }
		return errors
	}

	const [userLoginMutation, { data: loginUserData, isLoading: loginUserIsLoading, isSuccess: isLoginUserSuccess }] = useGetUserLoginMutation()

	const loginUser = async () => {
		try {
			const csrf_token = await getCSURFToken()
			const loginPayload = {
				username: groupState.email,
				password: groupState.password,
				device: deviceType()
			}
			const res = await userLoginMutation({ params: loginPayload, token: csrf_token }).unwrap()

			if (!res?.success) {
				setErrorState((state: any) => ({ ...state, isShow: true, message: res?.message || 'Login Failed. Please Try again' }))
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (loginUserData && isLoginUserSuccess) {
			if (loginUserData.success) {
				SaveAuthenticationSession(
					'user',
					loginUserData.data.accessToken,
					loginUserData.data.accessTokenExpiresAt,
					loginUserData.data.refreshToken,
					loginUserData.data.refreshTokenExpiresAt,
					loginUserData.data.client._id,
					loginUserData.data.user._id
				)
				dispatch(UpdateUserAuthDetails({
					token: loginUserData.data.accessToken,
					tokenExpiry: loginUserData.data.accessTokenExpiresAt,
					refreshToken: loginUserData.data.refreshToken,
					refreshTokenExpiry: loginUserData.data.refreshTokenExpiresAt,
					clientId: loginUserData.data.client._id,
					country: loginUserData.data.country,
					userId: loginUserData.data.user._id
				}))
				setCookie('username', groupState.email, true)
				history.push({
						pathname: '/join-group-success',
						state: { manager: groupState.invitor } 
				})
			}
			setIsLoading(false)
		}
	}, [loginUserData, isLoginUserSuccess])

	const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const targetId = event.target.id
		let errors = { ...groupState.formErrors }
		if (targetId === 'termsAgreement') {
			errors = { ...errors, [targetId]: groupState[targetId] !== true ? 'Make sure that you agree to the terms before you can proceed' : '' }
		} else if (targetId === 'firstName') {
			errors = { ...errors, [targetId]: groupState[targetId]?.trim() ? '' : 'First name is required' }
		} else if (targetId === 'lastName') {
			errors = { ...errors, [targetId]: groupState[targetId]?.trim() ? '' : 'Last name is required' }
		} else if (targetId === 'email') {
			errors = { ...errors, [targetId]: (groupState[targetId] && validateEmail(groupState[targetId])) ? '' : 'Please enter a valid email address' }
		} else if (targetId === 'password') {
			errors = { ...errors, [targetId]: groupState[targetId]?.trim().length >= 10 ? '' : 'Password must be at least 10 characters' }
		} else if (targetId === 'passwordConfirm') {
			errors = { ...errors, [targetId]: groupState.password !== groupState.passwordConfirm ? 'Password does not match' : groupState[targetId]?.trim().length < 10 ? 'Confirm password must be at least 10 characters' : '' }
		} else if (targetId === 'country') {
			errors = { ...errors, [targetId]: groupState[targetId] == 'all' ? '' : 'Country is required' }
		}
		setGroupState((state: any) => ({ ...state, formErrors: errors }))
	}

	const verifyErrors = () => {
		let errors = { ...groupState.formErrors }
		errors = { ...errors, ['firstName']: groupState['firstName']?.trim() ? '' : 'First name is required' }
		errors = { ...errors, ['lastName']: groupState['lastName']?.trim() ? '' : 'Last name is required' }
		errors = { ...errors, ['email']: (groupState['email'] && validateEmail(groupState['email'])) ? '' : 'Please enter a valid email address' }
		errors = { ...errors, ['password']: groupState['password']?.trim().length >= 10 ? '' : 'Password must be at least 10 characters' }
		errors = { ...errors, ['passwordConfirm']: groupState['passwordConfirm']?.trim().length >= 10 ? '' : 'Password confirmation must be at least 10 characters' }
		errors = { ...errors, ['passwordConfirm']: groupState.password === groupState.passwordConfirm ? '' : 'Password does not match' }
		errors = { ...errors, ['country']: groupState['country'] !== 'all' ? '' : 'Country is required' }
		errors = { ...errors, ['termsAgreement']: groupState['termsAgreement'] !== true ? 'Make sure that you agree to the terms before you can proceed' : '' }

		setGroupState((state: any) => ({ ...state, formErrors: errors }))
		return getErrors(errors)
	}

	const scrollToError = () => {
		if (groupState['termsAgreement'] !== true) {
			const errorElement = document.querySelector('#terms-agreement-list')
			errorElement?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const errorDisplay = (errorMessage: string) => {
		return <>
			{
				errorMessage && <div className='error-message'>
					<img src={'img/icons/icon_error_warning.svg'} />
					<span>{errorMessage}</span>
				</div>
			}
		</>
	}

	const handleCloseJoinModal = () => {
		setIsConfirmModal(false)
	}

	const handleProceedJoinModal = async() => {
		if (!verifyErrors()) {
			setIsLoading(true)
			try {
				const csrf_token = await getCSURFToken()

				const url = `${reverseObfuscateConstant(RP_ENV.API_URL_V2)}/user-group/join-group-new`
				const countrySelected = lookupCountries.data.data.find((country: any) => country._id === groupState.country)
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + User.accessToken,
						'x-api-key': RP_ENV.API_KEY,
						'XSRF-TOKEN': csrf_token
					},
					credentials: 'include',
					mode: 'cors',
					body: JSON.stringify({
						title: groupState.title,
						firstName: groupState.firstName,
						lastName: groupState.lastName,
						email: groupState.email,
						password: groupState.password,
						country: {
							...countrySelected
						},
						token: groupState.token
					})
				})
				const result = await response.json()
				if (!result.success) {
					let errors = { ...groupState.formErrors }
					errors = finalErrorValidation(result.message, errors)
					setGroupState((state: any) => ({ ...state, formErrors: errors }))

					setErrorState((state: any) => ({ ...state, isShow: true, message: result?.data?.message || 'Transaction failed. Please try again.' }))
				} else {
					setIsConfirmModal(false)
					loginUser()
				}
			} catch (error) {
				console.log(error)
			} finally {
				setIsLoading(false)
			}
		} else {
			scrollToError()
		}
	}

	const closeModalError = () => {
		setErrorState((state: any) => ({ ...state, isShow: false, message: '' }))
	}

	return <div className="single-entry">
		<JoinGroupNewUseForm>
			{isLoading && <LoadingOverlay />}
			{loginUserIsLoading && <LoadingOverlay />}
			<div>
				<div className="mnmd-block mnmd-block--fullwidth single-entry-wrap m-b-lg" role="main" >
					<article className="mnmd-block post post--single type-post status-publish format-standard has-post-thumbnail hentry category-abroad tag-landscape cat-5 margin-bottom-0px" >
						<TitlePageNewUser>
							<p className='main-title'>Join Group</p>
							<p className='sub-title'>Please create an account by filling in the form below to join <b>{groupState.invitor}</b>`s group</p>
						</TitlePageNewUser>
						<FormGroupNewUser>
							<p className='form-title'>Create an account</p>
							{/* <div>
								<label htmlFor='title-field' className='title'>Title</label>
								<select className='field' id='title' value={groupState.title} onChange={handleSelectChange} {...dataCySelector('title-select-field')}>
									{APP_CONFIG.SUBSCRIPTION.TITLES.map((title: string, subscription_title_key: number) => <option value={title} key={subscription_title_key} >{title}</option>)}
								</select>
							</div><br /> */}

							<div id='first-name-block'>
								<ValidatedField type='text' placeholder='First Name' name='first-name' id='firstName' value={groupState.firstName} onChange={handleChange} onBlur={handleBlur} className={groupState.formErrors.firstName ? 'error' : ''} required {...dataCySelector('firstname-input')} />
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.firstName)}</FieldErrorMessage>
							</div>

							<div id='last-name-block'>
								<ValidatedField type='text' placeholder='Last Name' name='first-name' id='lastName' value={groupState.lastName} onChange={handleChange} onBlur={handleBlur} className={groupState.formErrors.lastName ? 'error' : ''} required {...dataCySelector('lastname-input')} />
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.lastName)}</FieldErrorMessage>
							</div>

							<div id='email-block'>
								<ValidatedField type='email' placeholder='Email Address' name='email' id='email' value={groupState.email} onChange={handleChange} onBlur={handleBlur} className={groupState.formErrors.email ? 'error' : ''} pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" required {...dataCySelector('email-input')} />
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.email)}</FieldErrorMessage>
							</div>

							<div id='password-block'>
								<div className='password-container'>
									<ValidatedField placeholder='Password' type={showPassword ? 'text' : 'password'} name='password' id='password' autoComplete="off" value={groupState.password} onChange={handleChange} onBlur={handleBlur} className={groupState.formErrors.password ? 'error' : ''} required {...dataCySelector('password-input')} />
									{showPassword ? <Icons.InvincibleIcon height='32px' width='32px' onClick={() => setShowPassword(false)} /> : <Icons.VisibleIcon height='32px' width='32px' onClick={() => setShowPassword(true)} />}
								</div>
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.password)}</FieldErrorMessage>
							</div>

							<div id='confirm-password-block'>
								<div className='password-container'>
									<ValidatedField placeholder='Confirm Password' type={showConfirmPassword ? 'text' : 'password'} name='confirm-password' id='passwordConfirm' value={groupState.passwordConfirm} onChange={handleChange} onBlur={handleBlur} className={groupState.formErrors.passwordConfirm ? 'error' : ''} {...dataCySelector('confirm-password-input')} />
									{showConfirmPassword ? <Icons.InvincibleIcon height='32px' width='32px' onClick={() => setShowConfirmPassword(false)} /> : <Icons.VisibleIcon height='32px' width='32px' onClick={() => setShowConfirmPassword(true)} />}
								</div>
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.passwordConfirm)}</FieldErrorMessage>
							</div>

							<div id='country-block'>
								<ValidatedFieldSelect placeholder='Select Country' id='country' onChange={handleSelectChange} onBlur={handleSelectBlur} value={groupState.country} className={groupState.formErrors.country ? 'error' : ''} {...dataCySelector('country-select-field')}>
									<option value='all'>Select Country</option>
									{lookupCountries.data ? lookupCountries?.data?.data?.map((country: { _id: string, code: string, name: string }, key: number) => (<option key={key} value={country._id} >{country.name}</option>)) : null}
								</ValidatedFieldSelect>
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.country)}</FieldErrorMessage>
							</div>

							<ActionsContainer>
								<div className='checkbox-user'>
									<input type='checkbox' id='termsAgreement' value={groupState.termsAgreement} onChange={handleCheckChange} onBlur={handleBlur} {...dataCySelector('terms-agreement-checkbox')} />&ensp;
									<span>I have read and agreed to the terms of the <Link to={'/privacy-notice'}>Privacy Notice</Link> and the <Link to={'/subscription-agreement'}>Personal Subscription Agreement</Link></span>
								</div>
								<FieldErrorMessage>{errorDisplay(groupState.formErrors.termsAgreement)}</FieldErrorMessage>
								<br />
								<div className='activation-buttons'>
									{/* <ProceedButton onClick={handleJoinGroupNewUser} {...dataCySelector('join-group-btn')}>JOIN GROUP</ProceedButton> */}
									<ProceedButton onClick={handleProceedJoinModal} {...dataCySelector('join-group-btn')}>JOIN GROUP</ProceedButton>
								</div>
							</ActionsContainer>
						</FormGroupNewUser>
						<br />
					</article>
				</div>
			</div>
		</JoinGroupNewUseForm>

		{errorState?.isShow &&
			<ErrorModal messageBody={errorState?.message} isOpen={errorState?.isShow} onClose={closeModalError} />
		}

		{isConfirmModal &&
			<ConfirmJoinModal
				isOpen={isConfirmModal}
				onClose={handleCloseJoinModal}
				onProceed={handleProceedJoinModal}
				title='Confirm'
			>
				<JoinGroupModalContainer>
					{/* <div className='header'>You have been invited to join {existUserState.invitor}`s group.</div> */}
					<div className='header'>You have been invited to join <b>{groupState.invitor}</b>`s group.</div>
					<div className='value'>If you have any current subscriptions, they will be cancelled in favour of the managers Commercial subscription.</div>
				</JoinGroupModalContainer>
			</ConfirmJoinModal>
		}
	</div>
}

export default JoinGroupNewUser

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { GiftCardFormBody } from '../../gift-subscription/styles'
import { AddButton, AddSubscriptionActionContainer, CancelButton, ModalCloseButton } from '../styles'
import { useCreatePaymentMethodMutation, useUpdatePaymentMethodMutation } from '../../../../../app/services'
import { isMobile } from 'react-device-detect'
import AddUpdateForm from './AddUpdateForm'
import { getCSURFToken } from '../../../../../utils/CSRFToken'
import { useAppSelector } from '../../../../../app/hooks'
import { Users as UsersFromStore } from '../../../../../app/slices/Users'
import LoadingOverlay from '../../../../../components/Loading-overlay'
import ErrorModal from '../../../../../components/error-modal'
import moment from 'moment'

interface FormModalsProps {
	isOpen: boolean;
	onClose: (action: string) => void;
	title: string;
	data: any;
	setCardAddedStatus?: Dispatch<SetStateAction<boolean>>;
	setCardUpdatedStatus: Dispatch<SetStateAction<boolean>>;
	paymentToken?: string;
}

function FormModals({ data, isOpen, onClose, title, setCardAddedStatus, setCardUpdatedStatus }: FormModalsProps): ReactElement {
	const { handleSubmit, setValue, setError, control } = useForm<FieldValues>()
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
	const notifInit = {
		message: '',
		show: false,
	}
	const [showNotification, setShowNotification] = useState<{ message: string, show: boolean }>(notifInit)
	const [updatePayment, { isLoading: updateLoading }] = useUpdatePaymentMethodMutation()
	const [createPayment, { isLoading: createLoading }] = useCreatePaymentMethodMutation()
	const { userData } = useAppSelector(UsersFromStore)
	const [firstName, lastName] = data?.cardholderName?.split(' ') || ''
	const recaptchaRef = useRef(null)

	const initilizedDataValues = (data: any) => {
		setValue('cardHolderName', data?.cardholderName || '')
		setValue('cardHolderFirstName', firstName || '')
		setValue('cardHolderLastName', lastName || '')
		setValue('expiryMonth', data?.expirationMonth)
		setValue('expiryYear', data?.expirationYear)
		setValue('zipCode', data?.billingAddress?.postalCode)
	}

	useEffect(() => {
		initilizedDataValues(data)
	}, [])

	const closeModal = () => {
		setShowNotification(notifInit)
	}

	const dateConfirmation = (billingExpirationData: string) => {
		const todate = moment(new Date())
		return todate.isBefore(moment(billingExpirationData, 'MM/YYYY')) || billingExpirationData === todate.format('MM/YYYY')
	}

	const handleRecaptchaTokenGenerated = (token: string | null) => {
		setRecaptchaToken(token)
	}

	const handleRecaptchaReset = () => {
		if (recaptchaRef.current) {
			(recaptchaRef.current as any).reset()
			setRecaptchaToken(null)
		}
	}

	const onSubmitHandler = async (formData: FieldValues) => {
		const expirationDate = `${formData.expiryMonth} ${formData.expiryYear}`
		const paymentToken = data?.token
		const cardHolderName = formData?.cardHolderFirstName && formData?.cardHolderLastName ? `${formData?.cardHolderFirstName} ${formData?.cardHolderLastName}` : ''

		try {
			if (title.includes('Add')) {
				const payload = {
					name: cardHolderName || '',
					number: formData.cardNumber,
					cvv: formData.cvv,
					expirationDate: formData.expiryMonth + '/' + formData.expiryYear,
					postalCode: formData.zipCode,
					customerId: userData._id,
					'recaptcha_token': recaptchaToken
				}
				if (!recaptchaToken) {
					setShowNotification({
						message: 'Please verify the recaptcha.',
						show: true,
					})
					return
				}
				if (!dateConfirmation(expirationDate)) {
					setShowNotification({
						message: 'You cannot proceed with the payment because the credit card has expired.',
						show: true,
					})
					setError('expiryMonth', {
						type: 'custom',
						message: 'Selected expiry month is expired.'
					})
					setError('expiryYear', {
						type: 'custom',
						message: 'Selected expiry year is expired.'
					})
				} else {
					const csrf_token = await getCSURFToken()
					const createPaymentMethod: any = await createPayment({ params: payload, token: csrf_token })

					if (createPaymentMethod?.data?.success) {
						const endDate = moment(userData?.subscription?.end).add(1, 'day')
						const isExpired = endDate.isBefore(moment(), 'day')
	
						if (isExpired) {
							onClose('isExpired')
						} else {
							onClose('')
							setTimeout(() => {
								window.location.reload()
							}, 3000)
						}
						if (setCardAddedStatus)
							setCardAddedStatus(true)
					} else {
						handleRecaptchaReset()
						setShowNotification({
							message: createPaymentMethod?.data?.message || 'Payment update failed. Please try again.',
							show: true,
						})
					}
				}
			}
			if (title.includes('Update')) {
				const payload = {
					name: cardHolderName || '',
					number: formData?.cardNumber,
					cvv: formData?.cvv,
					expirationDate: formData?.expiryMonth + '/' + formData?.expiryYear,
					postalCode: formData?.zipCode,
					customerId: userData._id,
					token: paymentToken,
					'recaptcha_token': recaptchaToken
				}

				if (!recaptchaToken) {
					setShowNotification({
						message: 'Please verify the recaptcha.',
						show: true,
					})
					return
				}

				if (!dateConfirmation(expirationDate)) {
					setShowNotification({
						message: 'You cannot proceed with the payment because the credit card has expired.',
						show: true,
					})
					setError('expiryMonth', {
						type: 'custom',
						message: 'Selected expiry month is expired.'
					})
					setError('expiryYear', {
						type: 'custom',
						message: 'Selected expiry year is expired.'
					})
					handleRecaptchaReset() 
				}

				const csrf_token = await getCSURFToken()

				const updatePaymentQuery = await updatePayment({ params: payload, token: csrf_token })

				if ('data' in updatePaymentQuery) {
					if (updatePaymentQuery?.data?.success) {

						const endDate = moment(userData?.subscription?.end).add(1, 'day')
						const isExpired = endDate.isBefore(moment(), 'day')

						if (isExpired) {
							onClose('isExpired')
						} else {
							onClose('')
							setTimeout(() => {
								window.location.reload()
							}, 3000)
						}
						setCardUpdatedStatus(true)
						handleRecaptchaReset()
					} else {
						handleRecaptchaReset()
						setShowNotification({
							message: updatePaymentQuery?.data?.message || 'Payment update failed. Please try again.',
							show: true,
						})
					}
				}
			}
		} catch (error) {
			console.log({ error })
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmitHandler)}>
			<div className='backdrop-transition'>
				<div className={isOpen ? 'modal' : 'modal fade'} style={{ display: isOpen ? 'flex' : 'none', justifyContent: isMobile ? '' : 'center', alignItems: isMobile ? '' : 'center' }}>
					<div className={isMobile ? 'modal-dialog-subscription-mobile' : 'modal-dialog-subscription'}>
						<div className={isMobile ? 'modal-content-subscription-mobile' : 'modal-content-subscription'}>
							<ModalCloseButton isMobile={isMobile} onClick={() => onClose('')}><img src='/img/icons/close.png' /></ModalCloseButton>
							<div className="modal-body-subscription" style={{ paddingLeft: isMobile ? 20 : 0, paddingRight: isMobile ? 20 : 0 }}>
								<GiftCardFormBody isMobile={isMobile}>
									{/* {title == 'Update Billing Address' ? <UpdateBillingAddressForm control={control} title='Update Billing Address' onClick={handleSubmit(onSubmitHandler)} /> : <AddUpdateForm control={control} title={title} onClick={handleSubmit(onSubmitHandler)} />} */}
									<AddUpdateForm control={control} title={title} onRecaptchaTokenGenerated={handleRecaptchaTokenGenerated} recaptchaRef={recaptchaRef} />
									<AddSubscriptionActionContainer>
										<CancelButton onClick={() => onClose('')}>CANCEL</CancelButton>
										<AddButton onClick={handleSubmit(onSubmitHandler)}>{title == 'Update Card' ? 'UPDATE' : 'ADD'}</AddButton>
									</AddSubscriptionActionContainer>
								</GiftCardFormBody>
							</div>
						</div>
					</div>
				</div>
			</div>
			{createLoading && <LoadingOverlay />}
			{updateLoading && <LoadingOverlay />}
			{showNotification.show &&
				<ErrorModal messageBody={showNotification.message} isOpen={showNotification.show} onClose={closeModal} />
			}
		</form>
	)
}

export default FormModals